import React, { useState, useEffect } from "react";
import {
  WebMapTileServiceImageryProvider,
  WebMapServiceImageryProvider
} from "cesium";
import { ImageryLayer } from "resium";

type obj = Record<string, any>

const GetImageryProvider = (definitions: obj) => {
  const { type, providerOptions } = definitions;

  switch(type) {
    case "WMS":
      return new WebMapServiceImageryProvider(providerOptions);
    case "WMTS":
      return  new WebMapTileServiceImageryProvider(providerOptions);
    default:
      return undefined;
  }
}

const CustomImageryLayer: React.FC<{ definition:obj|undefined }> = ({ definition }) => {

  const [provider, setProvider] = useState<any>(undefined);
  const [options, setOptions] = useState<any>({});

  useEffect(() => {
    if(definition) {
      const newProvider = GetImageryProvider(definition);
      if(newProvider) {
        const { layerOptions } = definition;
        setOptions(layerOptions)
        setProvider(newProvider);
      }
    }
  }, [definition]);

  if(definition && provider) {
    return (
      <ImageryLayer
        {...options }
        imageryProvider={provider}
      />
    );
  }
  return null;
};

export { GetImageryProvider,  CustomImageryLayer };
