import React, {useState} from "react";
import {IonButton, IonGrid, IonIcon, IonPopover, IonThumbnail, IonTitle} from "@ionic/react";
import { useTranslation } from 'react-i18next';
import {getFilename} from "../layers/utils";


const LayerDescription: React.FC<{icon: any, dataLayer: string}> = ({icon, dataLayer}) => {
    const { t } = useTranslation();
    const [menuState, setMenuVisibility] = useState({ visible: false, event: undefined});

    if (!dataLayer) return null;

    return (<>
        <IonButton
            title={t(`default:description`)}
            onClick={
                (e: any) => {
                    e.persist();
                    setMenuVisibility({ visible: true, event: e })
                }}
        >
            <IonIcon slot="icon-only" icon={icon} />
        </IonButton>
        <IonPopover
            className="layer-popover"
            showBackdrop={false}
            event={menuState.event}
            isOpen={menuState.visible}
            onDidDismiss={() => setMenuVisibility({ visible: false, event: undefined })}
        >
            <IonGrid>
                <IonTitle>
                    {t(`default:layers.${dataLayer}.label`)}
                </IonTitle>
                <div>
                    <td dangerouslySetInnerHTML={{__html: t(`default:layers.${dataLayer}.description`,'')}} />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                        <img src={`/assets/layer_legends/${getFilename(dataLayer)}.png`} onError={(e) => (e.target as HTMLTextAreaElement).style.display = 'none'}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                        {t(`default:layers.${dataLayer}.legend`, '')}
                    </div>
                </div>
            </IonGrid>
        </IonPopover>
    </>);
}

export default LayerDescription;