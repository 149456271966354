import React from 'react';
import { IonSpinner } from '@ionic/react';
const Spinner: React.FC<{id?:string, message?:string}> = ({id, message}) => (
  <div
    id={id}
  >
    <IonSpinner className="spinner" name="lines" />
    {message?(<div className="message">{message}</div>):null}
  </div>
)

export default Spinner;
