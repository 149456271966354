import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IonList, IonItem, IonThumbnail, IonPopover, IonIcon, IonButton, IonLabel } from '@ionic/react';

import { connect } from 'react-redux'
import { setLanguage } from '../redux/reducer';
import configuration from "../config/config";
import {Language} from "../config/config.types";

const mapStateToProps = ( state: Record<string,string>) => ({
  language: state.language
})

const mapDispatchToProps = { setLanguage }

const languages: {[key in Language]: string} = {
  "en": "english",
  "es": "español",
  "fr": "français",
  "fi": "suomi",
  "ru": "русский"
}

const LanguageSelector: React.FC<{icon:string, language:string, setLanguage:any}> = ({icon, language, setLanguage }) => {

  const { t, i18n } = useTranslation();
  const [menuState, setMenuVisibility] = useState({ visible: false, event: undefined});

  useEffect(() => {
    console.log("language changed to ", language);
    i18n.changeLanguage(language);
  }, [language]);

    if(!configuration.options.languages || configuration.options.languages.length<=1) {
        return null;
    }

  return (
      <React.Fragment>
        <IonButton
          title={t(`default:choose_language`)}
          onClick={
          (e: any) => {
            e.persist();
            setMenuVisibility({ visible: true, event: e })
          }}
        >
          <IonIcon slot="icon-only" icon={icon} />
        </IonButton>
        <IonPopover
          className="lng-popover"
          showBackdrop={false}
          event={menuState.event}
          isOpen={menuState.visible}
          onDidDismiss={() => setMenuVisibility({ visible: false, event: undefined })}
        >
            <IonList
              className="lng-list"
            >
              {
                Object.entries(languages)
                .filter(([k, v]) => !configuration.options.languages || configuration.options.languages.includes(k as Language))
                .map(([k, v]) => (
                  <IonItem
                    key={k}
                    lines="none"
                    button={true}
                    color={k===language?'medium':'light'}
                    onClick={(e: any) => {
                        setLanguage(k);
                        setMenuVisibility({ visible: false, event: undefined });
                      }
                    }
                    className='lng-list-item'
                  >
                    <IonThumbnail
                      slot="start"
                      className="lng-thumb"
                    >
                      <img src={`/assets/flags/${k}.png`} />
                    </IonThumbnail>
                    <IonLabel
                      className="ion-text-capitalize ion-text-left"
                    >
                      {v}
                    </IonLabel>
                  </IonItem>
                ))
              }
            </IonList>
        </IonPopover>
      </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelector);
