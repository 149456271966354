import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonList, IonItem, IonThumbnail, IonPopover, IonIcon, IonButton, IonLabel, IonGrid, IonCol, IonRow } from '@ionic/react';
import { DataLayers, BaseLayers, OverlayLayers } from "../layers/Layers";
import {getFilename} from "../layers/utils";

type obj = Record<string, any>

const LayerSelector: React.FC<{icon:any, baseLayer: string, dataLayer: string, overlayLayer: string, setBaseLayer:any, setDataLayer:any, setOverlayLayer:any}> =
    ({icon, baseLayer, dataLayer, overlayLayer, setBaseLayer, setDataLayer, setOverlayLayer}) => {
  const { t } = useTranslation();
  const layerSorter = (a:obj,b:obj) => {
    //sort alphabetically according to language
    const labelA = t(`default:layers.${a.name}.label`).toUpperCase();
    const labelB = t(`default:layers.${b.name}.label`).toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0
  };

  const [menuState, setMenuVisibility] = useState({ visible: false, event: undefined});
  const splits = 2;
  const sortedBaseLayers = BaseLayers.sort(layerSorter);
  const sortedDataLayers = DataLayers.sort(layerSorter);
  const sortedOverlayLayers = OverlayLayers.sort(layerSorter);

  return (
    <React.Fragment>
      <IonButton
        title={t(`default:layers`)}
        onClick={
        (e: any) => {
          e.persist();
          setMenuVisibility({ visible: true, event: e })
        }}
      >
        <IonIcon slot="icon-only" icon={icon} />
      </IonButton>
      <IonPopover
        className="layer-popover"
        showBackdrop={false}
        event={menuState.event}
        isOpen={menuState.visible}
        onDidDismiss={() => setMenuVisibility({ visible: false, event: undefined })}
      >
        <IonGrid>
          <LayerBlock
            splits={splits}
            header={t("default:base_layers")}
            list={sortedBaseLayers}
            layer={baseLayer}
            setLayer={setBaseLayer}
            setMenuVisibility={setMenuVisibility}
            unselectable={true}
          />
            <LayerBlock
                splits={splits}
                header={t("default:overlay_layers")}
                list={sortedOverlayLayers}
                layer={overlayLayer}
                setLayer={setOverlayLayer}
                setMenuVisibility={setMenuVisibility}
                unselectable={false}
            />
          <LayerBlock
            splits={splits}
            header={t("default:data_layers")}
            list={sortedDataLayers}
            layer={dataLayer}
            setLayer={setDataLayer}
            setMenuVisibility={setMenuVisibility}
            unselectable={false}
          />
        </IonGrid>
      </IonPopover>
    </React.Fragment>
  )
}

const LayerBlock: React.FC<{ splits: number, header: string, list: Record<string,string>[], layer:any, setLayer:any, setMenuVisibility:any, unselectable?:boolean} > = ({ splits, header, list, layer, setLayer, setMenuVisibility, unselectable=false }) => {
  const splitLength = Math.ceil(list.length/splits);
  return (
    <React.Fragment>
      <IonRow className="title-row">
          <IonLabel
            className="ion-text-capitalize ion-text-center"
          >
            {header}
          </IonLabel>
      </IonRow>
      <IonRow
        className="layer-row"
      >
      {
        Array(splits).fill(0).map((v,i) => (
          <IonCol
            key={i}
          >
            <LayerSubBlock
              list={list.slice(i*splitLength, (i+1)*splitLength)}
              layer={layer}
              setLayer={setLayer}
              setMenuVisibility={setMenuVisibility}
              unselectable={unselectable}
            />
          </IonCol>
        ))
      }
      </IonRow>
    </React.Fragment>
  )
}

const LayerSubBlock: React.FC<{ list: Record<string,string>[], layer:any, setLayer:any, setMenuVisibility:any, unselectable:boolean} > = ({ list, layer, setLayer, setMenuVisibility, unselectable }) => {
  const { t } = useTranslation();
  return (
  <IonList
    className="layer-list"
  >
    {
      list
      .map((item) => (
          <IonItem
            lines="none"
            key={item.name}
            button={true}
            color={item.name===layer?'medium':'light'}
            onClick={(e: any) => {
                if(!unselectable || item.name!==layer) {
                  setLayer(item.name===layer?"":item.name);
                  /*
                  setMenuVisibility({ visible: false, event: undefined });
                  */
                }
              }
            }
            className='layer-list-item'
          >
            <IonThumbnail
              slot="start"
              className="layer-thumb"
            >
              <img src={`/assets/layer_thumbnails/${getFilename(item.id)}.png`} />
            </IonThumbnail>
            <IonLabel>{t(`default:layers.${item.name}.label`)}</IonLabel>
          </IonItem>
      ))
    }
  </IonList>
)}

export default LayerSelector;
