import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { earth, cloudCircleOutline, analyticsOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import Globe from "./pages/globe/Globe";
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonApp>
      <IonReactRouter basename={'/webapp'}>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/globe" component={Globe} exact={true} />
            <Route path="/tab2" component={Tab2} exact={true} />
            <Route path="/tab3" component={Tab3} />
            <Route path="/" render={() => <Redirect to="/globe" />} exact={true} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="tab1" href="/globe">
              <IonIcon icon={earth} />
              <IonLabel
                className="ion-text-capitalize ion-text-center"
              >
                {t("default:globe")}
              </IonLabel>
            </IonTabButton>
            {/*<IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={analyticsOutline} />
              <IonLabel
                className="ion-text-capitalize ion-text-center"
              >
                Tab 2
              </IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab3" href="/tab3">
              <IonIcon icon={cloudCircleOutline} />
              <IonLabel
                className="ion-text-capitalize ion-text-center"
              >
                Tab 3
              </IonLabel>
            </IonTabButton>*/}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
