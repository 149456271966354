import { createSlice } from '@reduxjs/toolkit';
import configuration from "../config/config";

interface ReduxState {
  baseLayer: string,
  dataLayer: string,
  overlayLayer: string,
  language: string,
}

const slice = createSlice({
  name: 'rootState',
  initialState: {
    baseLayer: configuration.initial.baseLayer,
    dataLayer: configuration.initial.dataLayer || '',
    overlayLayer: configuration.initial.overlayLayer || '',
    language: configuration.initial.language,
  } as ReduxState,
  reducers: {
    setBaseLayer(state, action) {
      return {
        ...state,
        baseLayer: action.payload
      }
    },
    setDataLayer(state, action) {
      return {
        ...state,
        dataLayer: action.payload
      }
    },
    setOverlayLayer(state, action) {
      return {
        ...state,
        overlayLayer: action.payload
      }
    },
    setLanguage(state, action) {
      return {
        ...state,
        language: action.payload
      }
    }
  },
});

const { setBaseLayer, setDataLayer, setOverlayLayer, setLanguage } = slice.actions;

export { setBaseLayer, setDataLayer, setOverlayLayer, setLanguage }
export default slice.reducer
