import {JulianDate} from "cesium";
import {DateTime} from "luxon";

export const dateFormatter = (date: any): string => {
    const isoString = JulianDate.toIso8601(date);
    let dateTime = DateTime.fromISO(isoString);
    dateTime = dateTime.setZone("local");
    // If you want to set a specific timezone
    // dateTime = dateTime.setZone("America/Chicago");
    return dateTime.toLocaleString(DateTime.DATE_MED);
};

export const timeFormatter = (date: any): string => {
    const isoString = JulianDate.toIso8601(date);
    let dateTime = DateTime.fromISO(isoString);
    dateTime = dateTime.setZone("local");
    // If you want to set a specific timezone
    // dateTime = dateTime.setZone("America/Chicago");
    return dateTime.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
};

export const datetimeFormatter = (date: any,): string => {
    const isoString = JulianDate.toIso8601(date);
    let dateTime = DateTime.fromISO(isoString);
    dateTime = dateTime.setZone("local");
    // If you want to set a specific timezone
    // dateTime = dateTime.setZone("America/Chicago");
    return dateTime.toLocaleString(DateTime.DATETIME_MED);
};