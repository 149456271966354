import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {Ion} from "cesium";

import i18n from "i18next";
import HttpApi  from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'

import reducer from './redux/reducer';

const store = configureStore({
  reducer: reducer,
  //devTools: process.env.NODE_ENV !== 'production'
});

//load layers configuration info
//store.dispatch(fetchLayerSourceInfo());

const { language } = store.getState();

i18n
.use(HttpApi)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  nonExplicitSupportedLngs: true,
  lng: language,
  fallbackLng: "en",
  ns:"default",
  defaultNS: "default",
  load: 'languageOnly',
  interpolation: {
    escapeValue: false
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  }
});

Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkZmMxZmYxZS01ZmEyLTRkODMtYTA2NS1kOTdjMDI4OWI3MzciLCJpZCI6Mzg5NjksImlhdCI6MTYwNjk5NDkyM30.HNC6ceeHnNBpSYbSCXVf3PAWGsrBrx4YcZkQS2CKoXE';

ReactDOM.render(
<React.Suspense
        fallback="loading..."
>
  <Provider store={store}>
    <App />
  </Provider>
</React.Suspense>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
